<template>
  <div class="auth-wrapper auth-v1 px-4">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            {{ getAppName() }}
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $i18n.t('two_factor_auth') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $i18n.t('messages.two_factor_auth') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group
                :label="$i18n.t('code')"
                label-for="code"
            >
              <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
              >
                <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                >
                  <b-form-input
                      id="code"
                      v-model="code"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="code"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-card-text class="mb-2">
              {{ $i18n.t('messages.resend_two_factor_auth') }}
              <b-link @click="resend()">
                {{ $i18n.t('resend_two_factor_auth_btn') }}
                <b-spinner
                    v-if="isLoadingReSend"
                    small
                />
              </b-link>
            </b-card-text>
            <!-- submit button -->
            <b-button
                block
                type="submit"
                variant="primary"
            >
              {{ $i18n.t('submit') }}
              <b-spinner
                  v-if="isLoadingSubmit"
                  small
              />
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import {required} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      code: '',
      isLoadingReSend: false,
      isLoadingSubmit: false,
      // validation
      required,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success && !this.isLoadingReSend && !this.isLoadingSubmit) {
          this.isLoadingSubmit = true
          this.$http.post('/2fa', {
            code: this.code,
          }).then(response => {
            this.isLoadingSubmit = false
            const token = response.data.data.access_token
            const {user} = response.data.data
            const isTwoFactorAuthRequired = false
            this.$ability.update(user.ability)
            this.$store.dispatch('auth/login', {token, user, isTwoFactorAuthRequired})
            let url = "/";
            if (this.can('photo-studio-photographer', 'read')) {
              url = "/fotostudio/search"
            }
            this.$router.replace(url)
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$i18n.t('Welcome')} ${user.name}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$i18n.t('messages.success_login'),
                    },
                  })
                })
          }).catch(error => {
            this.isLoadingSubmit = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'TrashIcon',
                variant: 'danger',
                text: error.data.message,
              },
            })
          })
        }
      })
    },
    resend() {
      if (!this.isLoadingReSend && !this.isLoadingSubmit) {
        this.isLoadingReSend = true
        this.$http.get('/2fa/resend').then(response => {
          this.isLoadingReSend = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$i18n.t('Success'),
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        }).catch(error => {
          this.isLoadingReSend = false
        })
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style>
.auth-wrapper.auth-v1 .auth-inner {
  max-width: 500px;
}
</style>
